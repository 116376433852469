
/* MEDIA QUERIES */
@media (max-width: 1000px) { /* < 1000px */
    #container {
        grid-template-rows: 20vh 1fr 5vh; /* IMPORTANT: Try to use more 'vh' parametrers */
        gap: 0;
        height: 100vh;
    }
    #header {
        font-size: 0.7em;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr;
        padding: 0;
        margin: 0;
    }
    #header_one {
        font-size: 1.1em;
        display: grid;
        align-items: center;
        justify-items: center;
    }
    #header_two {
        grid-row: 2/3;
        margin: 5px;
        padding: 10px;
    }
    #logo {text-align: center}
    #cart-size {font-size: 1.6em}
    #home-content {
        grid-template-rows: 0.4fr 1fr 0.7fr;
        grid-template-columns: 1fr;
        font-size: 0.8em;
        padding: 0;
    }
    #shop-now {
        width: 200px;
        height: 80px;
    }
    #game-samples {
        grid-row: 1/3;
        grid-column: 1/2;
        opacity: 10%;
        width: 60vw;
        justify-self: center;
    }
    #footer {
        font-size: 1.1em;
        padding: 0;
    }
    #shop-content {
        padding: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
@media (min-width: 1000px) and (max-width: 1400px) { /* 1000px -> 1400px */
    #header, #home-content {font-size: 0.8em}
}
@media (min-width: 1400px) and (max-width: 1600px) { /* 1400px -> 1600px */
    #header, #home-content, #footer {font-size: 0.9em}
}
