/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */
@import url('https://fonts.googleapis.com/css?family=Montserrat');
* {
    font-family: montserrat, sans-serif;
    user-select: none;   /* Disables text highlighting. Individually removed later for to-do items */
}
body,html {
    margin: 0;
    padding: 0;
}
a:-webkit-any-link {    /* Removes default styling for links, and React Links */
    text-decoration: none;
    color: inherit;
}
/*-----------------*/
body {background-color: #1f143d}

#container {
    display: grid;
    grid-template-rows: 210px 1fr 50px;
    height: 100vh;
}
/* HEADER */
#header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background-color: #0c0020;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
}
#logo {
    color: aqua;
    font-size: 3em;
    font-weight: 1000;
    text-decoration: underline 0.15em rgba(0, 0, 0, 0);
    transition: color 0.5s, text-decoration-color 0.6s;
}
#logo:hover {
    color:antiquewhite;
    text-decoration-color: antiquewhite;
}
#header_two {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    color: white;
    padding: 15px;
    margin: 15px;
}
#home, #shop, #about, #cart {
    font-size: 2.3em;
    font-weight: 1000;
    transition-property: font-size;
    transition-duration: 0.2s;
    align-self: center;
    justify-self: center;
    cursor: pointer;
}
#cart-container {
    display: grid;
    grid-template-columns: 1fr 35px;
}
#cart {
    font-size: 3em;
    justify-self: end;
}
#cart-size {
    border: 3px solid #0ebf83;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    align-self: center;
    justify-self: center;
    padding: 4px;
    text-align: center;
    font-size: 1.1em;
    font-weight: 900;
}
#cart:hover {
    font-size: 4em;
    color: aqua;
}

/* Home Page */
#home-content {
    display: grid;
    gap: 30px;
    grid-template-rows: 70px 200px 150px;
    grid-template-columns: 1.2fr 1.3fr;
    background-color: #1f143d;
    color: white;
    padding: 80px;
}
#home-text {
    align-self: end;
    grid-row: 1/2;
    grid-column: 1/2;
    font-size: 1.5em;
    text-align: center;
    color: #6e6d93;
}
#main-home-text {
    align-self: center;
    grid-row: 2/3;
    grid-column: 1/2;
    font-size: 2.8em;
    font-weight: 800;
    text-align: center;
}
.shop-btn {
    align-self: start;
    justify-self: center;
    border-radius: 100px;
    font-weight: bolder;
    background-color: #82fad2;
    border: 0;
    display: grid;
    align-items: center;
    justify-items: center;
    /* Transitions for hover effect */
    transition: transform 0.2s;
}
.shop-btn:hover {transform: scale(1.12)}
#shop-now {
    grid-row: 3/4;
    grid-column: 1/2;
    font-size: 2.5em;
    width: 400px;
    height: 110px;
    color: black;
}
#game-samples {
    grid-row: 1/4;
    grid-column: 2/3;
    border-radius: 15px;
    opacity: 90%;
    width: 550px;
    align-self: center;
    justify-self: start;
}

/* Cart Popup */
#cart-popup-container {
    position: fixed;
    right: 0px;
    width: 400px; /* To change based on screen size */
}
#cart-popup {
    height: 95vh; /* To change and make 100vh once click out feature added */
    background-color: #0c0020;
    border: 3px solid #1f143d;
    color: red;
    position: relative;
    /* Animation */
    animation-name: cartpopup;
    animation-duration: 0.5s;
    /* Creating a grid display for cart content */
    display: grid;
    gap: 30px;
    grid-template-rows: 30px 1fr 190px;
    padding: 40px;
}
@keyframes cartpopup {
    from {
        right: -80px; 
        opacity: 0
    }
    to {
        right: 0px;
        opacity: 1
    }
}
.cart-text {
    align-self: center;
    align-items: center;
    color: white;
    font-size: 1.2em;
    display: grid;
    grid-template-columns: 100px 1fr 20px;
}
.sub-cart {
    color: #7b778f;
    font-size: 0.8em;
}
#close-cart {
    justify-self: center;
    color: white;
    font-weight: 800;
    cursor: pointer;
}
#close-cart:hover {color: #7b778f}
#cart-content {
    color: white;
    font-weight: 900;
    font-size: 0.95em;
    display: grid;
    align-items: start;
}
.product-forcart {
    display: grid;
    grid-template-columns: 1fr 80px;
    align-items: center;
}
#cart-footer {
    display: grid;
    grid-template-rows: 100px 100px;
    grid-template-columns: 150px 1fr;
    align-items: center;
}
#subtotal {
    grid-column: 1/2;
    grid-row: 1/2;
    font-size: 1.3em;
}
#total-price {
    grid-column: 2/3;
    grid-row: 1/2;
    font-size: 1.6em;
}
#checkout-btn {
    grid-row: 2/3;
    grid-column: 1/3;
    font-size: 1.7em;
    font-weight: 600;
    width: 300px;
    height: 60px;
    cursor: pointer;
}
#home:hover, #shop:hover, #about:hover {
    font-size: 2.9em;
    color: aqua;
}

/* CONTENT */
#content {background-color:rgb(214, 245, 245)}

/* SHOP */
#shop-content {
    display: grid;
    /* grid-template-rows: repeat(2, 1fr); */
    grid-template-rows: repeat(auto-fit, minmax(300px, 1fr));
    /* grid-template-columns: repeat(3, 1fr); */
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
    padding: 40px 80px 40px 80px;
    background-color: #1f143d;
    color: white;
}
.product {
    display: grid;
    grid-template-rows: 1fr 70px 30px 30px;
    grid-template-columns: repeat(2, 1fr);
    align-self: center;
    justify-self: center;
    align-items: center;
    background-color: #0c0020;
    border-radius: 10px;
    padding: 15px;
    transition: transform 0.2s; /* Transitions for hover effect */
}
.product-img {
    grid-column: 1/3;
    width: 300px;
    height: 400px;
    border-radius: 10px;
    transition: transform 0.2s; /* Transitions for hover effect */
}
.product-img:hover {
    transform: scale(1.08);
    opacity: 0.9;
}
.steam-logo {width: 25%}
.product-title {
    grid-column: 1/3;
    grid-row: 2/3;
    text-transform: uppercase;
    border-bottom: 1px solid #251a37;
    padding: 20px 0 20px 0;
}
.product-title:hover {
    text-decoration: underline;
    text-decoration-color: white;
}
.product-price {
    grid-column: 1/2;
    grid-row: 3/4;
    font-size: 1.5em;
}
.product-discount {
    grid-column: 1/3;
    grid-row: 4/5;
    text-decoration: line-through;
}
.product > .material-symbols-outlined {
    cursor: pointer;
    grid-column: 2/3;
    grid-row: 3/5;
    justify-self: end;
    padding: 20px;
    font-size: 2.4em;
    /* Transitions for hover effect */
    transition: transform 0.2s;
}
.product > .material-symbols-outlined:hover {
    transform: scale(1.5);
    color: #0ebf83;
}
/* ABOUT */
#about-content {
    display: grid;
    grid-template-rows: 0.5fr 1fr;
    gap: 30px;
    background-color: #1f143d;
    color: white;
    padding: 30px;
}
#about-links {
    cursor: pointer;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    align-self: end;
    justify-self: center;
}
#about-links > a {font-size: 5em}
#github-logo:hover {color: #171515}
#linked-logo:hover {color: #0072b1}
#insta-logo:hover {color: #833AB4}
#youtube-logo:hover {color: red}
#contact-info {
    font-weight: 800;
    font-size: 2em;
    align-self: start;
    justify-self: center;
    text-align: center;
}
/* FOOTER */
#footer {
    display: grid;
    background-color: #0ebf83;
    color: rgb(0, 0, 0);
    font-size: 1.1em;
    font-weight: 700;
    text-align: center;
    padding: 10px;
    align-content: center;
}
#git {
    cursor: pointer;
    width: 1.0em;
    transition: transform 1.1s;
}
#git:hover {transform: rotate(360deg) scale(2)}
